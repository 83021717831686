<template>
  <nuxt-link
    :to="localePath(props.to, locale)"
    :class="$style.root"
    :data-route-name="routeName"
  >
    <slot />
  </nuxt-link>
</template>

<script setup lang="ts">
const localePath = useLocalePath()

const props = defineProps({
  to: {
    type: [String, Object]
  },
  locale: {
    type: String,
    optional: true
  }
})

const routeName = props?.to?.name
</script>

<style module>
.root {
  composes: reset-link from global;
}

.root[disabled] {
  pointer-events: none;
}
</style>
