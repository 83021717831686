<template>
  <div :class="$style.root" />
</template>

<script setup>
</script>

<style module>
.root {
  height: var(--stroke--thick);

  width: 100%;
  border-radius: 9999px;
  background-color: currentColor;

  margin-left: calc(var(--unit--horizontal) * -1);
  margin-right: calc(var(--unit--horizontal) * -1);
  width: calc(100% + var(--unit--horizontal) * 2);
}
</style>
