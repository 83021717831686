export const i18nGetLocalizedData = (data: object) => {
  const currentLanguageCode = baseI18nGetActiveCode()
  if (!data) return null
  if ((data instanceof Object) === false) return null
  let newData = data?.constructor === Array ? [] : {}

  for (const [key, value] of Object.entries(data)) {
    const isI18nKey = key?.startsWith('i18n_') && key?.endsWith(`_${currentLanguageCode}`)
    if (value instanceof Object) {
      Object.assign(newData, { [key]: i18nGetLocalizedData(value) })
    } else if (isI18nKey) {
      Object.assign(newData, { [key]: value });
      if (value) {
        Object.assign(newData, { 'current_locale': value });
      }
    } else {
      Object.assign(newData, { [key]: value });
    }
  }

  return newData
}
